import React, { useState, useEffect } from 'react';
import ScrollLock from 'react-scrolllock';

import appleDownload from 'images/app_store.webp';
import googleDownload from 'images/google_play.webp';
import menu from 'images/menu_black.webp';

import Meta from 'components/Meta';
import HomeFooter from 'components/HomeFooter';
import MobileMenu from 'components/MobileMenu';

import '../styles/index.scss';

const Header = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const onMobileMenuClick = () => {
    setShowMobileMenu((s) => !s);
  };
  return (
    <>
      <header className={'home'}>
        <div className={'container'}>
          <div className="logo-container">
            <a href="#">
              <img src={'swipr-logo.webp'} />
            </a>
          </div>
          <div className={'mobile-menu'}>
            <a href="#" onClick={onMobileMenuClick}>
              <img src={menu} width={32} height={32} />
            </a>
          </div>
        </div>
      </header>
      <MobileMenu show={showMobileMenu} onMobileMenuClick={onMobileMenuClick} />
      <ScrollLock isActive={showMobileMenu} />
    </>
  );
};

const MainContent = () => {
  return (
    <div className={'main-content'}>
      <div className={'container'}>
        <h1 className="swipr">
          Make Friends
          <br />
          around
          <br />
          the World
          <div className={'swipr-app-download'}>
            <div
              className={'swipr-app-download-btn'}
              style={{ marginBottom: 10 }}
            >
              <a href="https://apps.apple.com/mo/app/swipr/id1537012560">
                <img src={appleDownload} />
              </a>
            </div>
            <div className={'swipr-app-download-btn'}>
              <a href="https://play.google.com/store/apps/details?id=com.guppir.swipr">
                <img src={googleDownload} />
              </a>
            </div>
          </div>
        </h1>
        <img className={'background'} src={`swipr-bg.webp`} />
      </div>
    </div>
  );
};

const MobileMainContent = () => {
  const [isIOS, setIsIOS] = useState(false);
  useEffect(() => {
    setIsIOS(/(iPad|iPhone|iPod)/g.test(window.navigator.userAgent));
  }, []);
  return (
    <div className={'mobile-main-content swipr'}>
      <h1>
        Make Friends
        <br />
        around
        <br />
        the World
      </h1>
      <div className={`app-download-btn ${isIOS ? 'ios' : ''}`}>
        {isIOS ? (
          <a href="https://apps.apple.com/mo/app/swipr/id1537012560">
            <img src={appleDownload} width={151} height={48} />
          </a>
        ) : (
          <a href="https://play.google.com/store/apps/details?id=com.guppir.swipr">
            <img src={googleDownload} width={151} height={48} />
          </a>
        )}
      </div>
    </div>
  );
};

const IndexPage = () => {
  return (
    <>
      <Meta />
      <main
        className={'home'}
        style={{
          width: '100%',
        }}
      >
        <Header />
        <MainContent />
        <MobileMainContent />
        <HomeFooter />
      </main>
    </>
  );
};

export default IndexPage;
